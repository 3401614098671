<template>
  <div class="print-payment-page">
    <div class="payment-header">
      <div class="payment-header-row">
        <div class="payment-header-item">
          <b>{{ $t('accountancy.documents.print.payment.paymentReceipt') }}</b>
        </div>

        <div class="payment-header-item">
<!--          <img class="shop-logo" src="https://tennisshop.ir/wp-content/uploads/2021/04/LOGO-TENNIS-SHOP.png"-->
<!--               alt="tennis_shop">-->
          <img class="shop-logo" :src="getClubLogo" alt="tennis_shop">
        </div>

        <div class="payment-header-item">
          <div class="flex"><b>{{ $t('accountancy.documents.print.payment.date') }}</b> <span class="date">{{ payment.date }}</span></div>
          <div><b>{{ $t('accountancy.documents.print.payment.docNumber') }}</b> {{ payment.serial }}</div>
        </div>

      </div>
    </div>

    <div class="payment-body">
      <div class="payment-details">
        <template v-for="(item, key) in payment.description">
          <div v-if="item.hasOwnProperty('value')"
               :class="item.class ? `detail-item ${item.class}` : 'detail-item'"
               :style="item.style ? item.style : ''"
               :key="key">
            {{ item.value }}
          </div>
          <div v-else :key="key" class="detail-item">
            {{ item }}
          </div>
        </template>
      </div>
    </div>
    <div class="payment-signatures">
      <div class="signature-item">
        {{ $t('accountancy.documents.print.payment.nameAndSignature') }}
        <br>
        {{ $t('accountancy.documents.print.payment.receiver') }}
      </div>
      <div class="signature-item">
        {{ $t('accountancy.documents.print.payment.nameAndSignature') }}
        <br>
        {{ $t('accountancy.documents.print.payment.payer') }}
      </div>
      <div class="signature-item">
        {{ $t('accountancy.documents.print.payment.nameAndSignature') }}
        <br>
        {{ $t('accountancy.documents.print.payment.confirmer') }}
      </div>
    </div>

    <div class="btn-controls">
      <button @click="printPage" class="btn">
        {{ $t('accountancy.documents.print.payment.print') }}
      </button>
      <button class="btn">
        {{ $t('accountancy.documents.print.payment.back') }}
      </button>
    </div>
  </div>
</template>

<script>
import Num2persian from 'num2persian'
import {addComma} from '../../../../../assets/js/functions'
import {getPayment} from "../../../../../http/requests/documents";

export default {
  name: 'PrintPayment',

  props: {
    paymentData: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      sourceTypesMapper: {
        'User': '',
        'Bank': this.$t('accountancy.documents.print.cost.bank'),
        'Cash': this.$t('accountancy.documents.print.cost.cashBox'),
        'Storeroom': this.$t('accountancy.documents.print.cost.storeroom'),
        'PaymentGateway': this.$t('accountancy.documents.print.cost.paymentGateway')
      },
      payment: {
        date: '',
        serial: '',
        description: []
      }
    }
  },

  computed: {
    getClubLogo () {
      return this.$store.state.setting.clubInfo.club_logo
    }
  },

  created () {
    this.getPayment()
  },

  methods: {
    getPayment () {
      getPayment(this.$route.params.id).then(response => {
        const data = response.data.data
        this.payment.date = data.date
        this.payment.serial = data.id
        this.payment.description.push({
          value: this.$t('accountancy.documents.print.payment.description.confirm'),
          style: 'min-width: 100%;'
        })
        this.payment.description.push(this.$t('accountancy.documents.print.payment.description.price'))
        this.payment.description.push({
          value: `${addComma(Math.abs(data.price))  } ${this.$t('currency')}`,
          class: 'grow'
        })
        this.payment.description.push(this.$t('accountancy.documents.print.payment.description.toText'))
        this.payment.description.push({
          value: `${Num2persian(Math.abs(data.price))  } ${this.$t('currency')}`,
          class: 'grow'
        })
        this.payment.description.push(this.$t('accountancy.documents.print.payment.description.payee'))
        this.payment.description.push({
          value: `${data.payee.name || ''} ${data.payee.family || ''}`,
          class: 'grow'
        })
        this.payment.description.push(this.$t('accountancy.documents.print.payment.description.inDate'))
        this.payment.description.push({
          value: data.date,
          class: 'grow rtl'
        })
        if (data.description) {
          this.payment.description.push(this.$t('accountancy.documents.print.payment.description.description'))
          this.payment.description.push({
            value: data.description,
            class: 'grow'
          })
        }
        this.payment.description.push(this.$t('accountancy.documents.print.payment.description.payer'))
        this.payment.description.push({
          value: `${data.payer.name}`,
          class: 'grow'
        })
        this.payment.description.push(this.$t('accountancy.documents.print.payment.description.payed'))
      })
    },

    printPage () {
      window.print()
    }
  }
}
</script>

<style lang="scss">
  $divider: #cdcdcd;

  div#app {
    background-color: #fff;
  }

  .print-payment-page {
    width: 21cm;
    margin: auto;
    background: #fff;
    padding: .5cm;
    font-weight: 400;
    font-size: 13px;
    line-height: 30px;

    .payment-header {
      border: 1px solid #cecece;
      padding: 10px;

      .payment-header-row {
        display: flex;

        .payment-header-item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          flex-grow: 1;
          width: 25%;
          font-weight: 500;
          text-align: center;

          .date {
            direction: rtl;
            margin-left: 5px;
          }

          &:first-child {
            font-size: 13pt;
          }

          &:nth-child(odd) {
            align-items: start;
          }

          &:nth-child(2) {
            width: 50%;
          }
        }

        div, b {
          flex: 1;
          line-height: 50px;
        }

        img {
          object-fit: scale-down;
          height: 100px;
        }
      }
    }

    .payment-body {
      padding: 10px 20px;
      border: 1px solid $divider;
      border-top: 0;
      min-height: 220px;
      line-height: 45px;

      .payment-details {
        display: flex;
        flex-wrap: wrap;

        .detail-item {
          min-width: 15%;

          &.grow {
            text-align: center;
            font-weight: bold;
            min-width: 35%;
          }
        }
      }
    }

    .payment-signatures {
      display: flex;
      justify-content: space-between;
      border: 1px solid $divider;
      border-top: 0;

      .signature-item {
        width: 33.33%;
        min-height: 120px;
        padding: 10px 20px;
        border-right: 1px solid $divider;

        &:last-child {
          border-right: 0;
        }
      }
    }

    .btn-controls {
      margin-top: 50px;
      display: flex;
      justify-content: center;

      .btn {
        cursor: pointer;
        font-weight: 500;
        font-size: 13px;
        line-height: 40px;
        background: #cecece50;
        border: 1px solid #cecece;
        border-radius: .5rem;
        min-width: 120px;
        transition: all .3s ease;

        &:first-child {
          margin-right: 10px;
        }

        &:hover {
          background: #cecece90;
        }
      }
    }

    @media print {
      * {
        font-size: 12pt !important;
      }

      .header-item {
        font-size: 13pt !important;
      }

      .signature-item {
        font-size: 12pt !important;
      }

      .btn-controls {
        display: none !important;
      }
    }
  }

  @page {
    margin: 5mm;
  }

</style>
